import { Link } from 'react-router-dom';
import GooglePlayImage from '../../assets/icons/svg/google_play_icon.svg';
import GooglePlayLogoEng from '../../assets/images/svg/play_store_logo_eng.svg';
import { Box } from '@mui/material';

const GooglePlay = ({
    width = { xs: '100px', md: '143px' }
}: {
    width?: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
}) => {
    // const { i18n } = useTranslation();
    const language = 'en-US';

    return (
        <Link to="https://play.google.com/store/apps/details?id=com.sitiouno.mytestament.prod" target="_blank">
            <Box
                sx={(theme) => ({
                    height: 'max-content',
                    [theme.breakpoints.up('xs')]: {
                        width: width.xs
                    },
                    [theme.breakpoints.up('sm')]: {
                        width: width.sm
                    },
                    [theme.breakpoints.up('md')]: {
                        width: width.md
                    },
                    [theme.breakpoints.up('lg')]: {
                        width: width.lg
                    },
                    [theme.breakpoints.up('xl')]: {
                        width: width.xl
                    }
                })}
            >
                <img
                    src={language === 'en-US' ? GooglePlayLogoEng : GooglePlayImage}
                    alt="Download it from Google Play Store"
                    width="100%"
                />
            </Box>
        </Link>
    );
};

export default GooglePlay;
